import { useRef, useState ,useEffect } from "react";
import "./contact.scss";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { FaTimes } from 'react-icons/fa'; // FaTimes is the close icon from Font Awesome


const variants = {
  initial: {
    y: 500,
    opacity: 0,
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      staggerChildren: 0.1,
    },
  },
};





const Contact = ({ showContactUs, setShowContactUs }) => {
  const ref = useRef();
  const formRef = useRef();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false); // Track loading state



  const sendEmail = async (e) => {
    e.preventDefault();
        setLoading(true);
    setSuccess(false);
    setError(false);
    try {
      const result = await emailjs.sendForm(
        "service_3imbgc6",  // Your service ID
        "template_5faxwjp",  // Your template ID
        formRef.current,     // The form reference
        "pbJ87q8ElhJbdpsUE"  // Your user ID
      );
  
      setSuccess(true);
      localStorage.setItem('bajajEmiCalculatorFeedback', 'true');

    } catch (error) {
      setError(true);
    } finally {
      setLoading(false);
      handleClose()
      
    }
  };
  

  const handleClose = () => {
    setShowContactUs(!showContactUs)
  }





  return (
    <motion.div
      ref={ref}
      className="contact"
      variants={variants}
      initial="initial"
      whileInView="animate"
    >
      <FaTimes className="forclose" size={30} color="red" onClick={handleClose} />

      <motion.div className="textContainer" variants={variants}>
        <motion.h1 variants={variants}>Give Feedback Here</motion.h1>
        <motion.div className="item" variants={variants}>
        </motion.div>
      </motion.div>
      <div className="formContainer">
        <motion.form
          ref={formRef}
          onSubmit={sendEmail}
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
        >
          <input type="text" required placeholder="Your Name..." name="name" />
          <input
            type="tel"
            pattern="^\d{10}$"
            required
            placeholder="Your Phone Number..."
            name="phone"
            minlength="10"
            maxlength="10"
            title="Please enter a valid 10-digit phone number."
          />

          <textarea rows={8} placeholder="Write anything here as feedback..." name="message" />
          <button>Submit</button>
          {loading && <div className="loading"></div>} {/* Show loading spinner */}

          {error && "Error"}
          {success && "Success"}
        </motion.form>
      </div>
    </motion.div>
  );
};

export default Contact;