import { useState, useEffect } from "react";
import "./app.css";
import FormInput from "./components/FormInput";
import Contact from "./components/Contact";


const App = () => {
  const [showCustomInput, setShowCustomInput] = useState(false);
  const [emiResult, setEmiResult] = useState();
  const [totalPayment, settotalPayment] = useState()
  const [totalInterest, settotalInterest] = useState()
  const [bikeOnRoadPrice, setbikeOnRoadPrice] = useState();
  const [bikeDownPayment, setbikeDownPayment] = useState()
  const [LoanTurnue, setLoanTurnue] = useState()
  const [showContactUs, setShowContactUs] = useState(false);
  const [actualDownPayment, setactualDownPayment] = useState();
  const [scheme, setScheme] = useState('NormalScheme');
  const [loanforLowPayment, setloanforLowPayment] = useState()



  const [LTVvalue, setLTVvalue] = useState();
  const [loanforLow, setloanforLow] = useState();

  const [res, setRes] = useState("");

  const [values, setValues] = useState({
    BikePriceOnRoadPrice: "",
    BikePriceSystemPrice: "",
    DownPayment: "",
    ProcessingCharge: "3.5",
    OthersCharge: "1200",
    DealerCharge: "5000",
    CustomProcessingCharge: "",
    Percentage: "98"
  });
  console.log(values)
  const [anothervalues, setAnothervalues] = useState({
    RequiredLoanAmount: "",
    InterestRate: "",
    LoanTenure: ""
  })



  const inputs = [
    {
      id: 1,
      name: "BikePriceOnRoadPrice",
      type: "number",
      placeholder: "(On-Road)",
      label: "Bike Price (On-Road)",
      required: true,
    },
    {
      id: 2,
      name: "BikePriceSystemPrice",
      type: "number",
      placeholder: "(System-price)",
      label: "Bike Price (System-price)",
      required: true,
    },
    scheme === 'NormalScheme' ?
      {
        id: 3,
        name: "DownPayment",
        type: "number",
        placeholder: "Customer's Down Payment",
        label: "Customer's Down Payment",
        required: true,
      } :
      {
        id: 4,
        name: "Percentage",
        type: "number",
        placeholder: "LTV (Loan-to-value) ratio (%)",
        label: "LTV (Loan-to-value) ratio (%)",
        required: true,
      }


  ];



  const inputs2 = [
    {
      id: 1,
      name: "OthersCharge",
      type: "number",
      placeholder: "Others Charge",
      label: "Others Charge",
      required: true,
    },
    {
      id: 2,
      name: "DealerCharge",
      type: "number",
      placeholder: "Dealer Charge",
      label: "Dealer Charge",
      required: true,
    },



  ];



  const anotherinputs = [
    {
      id: 1,
      name: "RequiredLoanAmount",
      type: "number",
      placeholder: "Loan Amount",
      label: "Loan Amount",
      required: true,
      readOnly: true, // Make the input read-only
      className: "non-editable-input", // Add a custom class for styling

    },

    {
      id: 2,
      name: "LoanTenure",
      type: "number",
      placeholder: "Loan Tenure(Month)",
      label: "Loan Tenure(Month)",
      required: true,
    },
    {
      id: 3,
      name: "InterestRate",
      type: "number",
      placeholder: "Interest Rate(%)",
      label: "Interest Rate(%)",
      required: true,
    },

  ];


  useEffect(() => {
    if (res !== "") {
      setAnothervalues(prevValues => ({
        ...prevValues,
        RequiredLoanAmount: res
      }));
    }
  }, [res]);


  useEffect(() => {

    const feedbackStatus = localStorage.getItem('bajajEmiCalculatorFeedback');
    if (feedbackStatus === 'true') {
      setShowContactUs(false);  // Close the form if feedback is true
    }
    else {
      setShowContactUs(true);
    }
  }, []);





  const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(`
    Bike Price (On-Road): ₹${Math.round(bikeOnRoadPrice)}
    ${scheme === 'NormalScheme' ? `Customer's Down Payment: ₹${Math.round(bikeDownPayment)}` : ''}
    Loan Tenure (Month): ${LoanTurnue}
    Total Interest: ₹${Math.round(totalInterest)}
    Total Payment: ₹${Math.round(totalPayment)}
    ${scheme === 'NormalScheme' ? `Actual Down Payment: ₹${Math.round(actualDownPayment)}` : `Low Down Payment: ₹${Math.round(loanforLowPayment)}`}
    EMI Amount (per month): ₹${Math.round(emiResult)}
  `)}`;

  // We can also log the generated URL to see the result before using it
  console.log(whatsappShareUrl);

  const handleSubmit = (e) => {
    e.preventDefault();
    setbikeOnRoadPrice(parseFloat(values.BikePriceOnRoadPrice));
    setbikeDownPayment(parseFloat(values.DownPayment));
    const loan = parseFloat(values.BikePriceOnRoadPrice) - parseFloat(values.DownPayment);
    const beforePCharge = values.ProcessingCharge === "others" ? values.CustomProcessingCharge : values.ProcessingCharge
    // console.log(beforePCharge)
    const pcharge = parseFloat(loan) * parseFloat(beforePCharge) / 100;
    const ocharge = parseFloat(values.OthersCharge)
    const dcharge = parseFloat(values.DealerCharge)
    const res = parseFloat(loan) + parseFloat(pcharge) + parseFloat(ocharge) + parseFloat(dcharge)
    setRes(res);
    const LTV = parseFloat(res) / parseFloat(values.BikePriceSystemPrice) * 100
    // console.log(parseFloat(LTV))
    setLTVvalue(parseFloat(LTV));
    const acDownPayment = parseFloat(values.BikePriceOnRoadPrice) - parseFloat(res) + (parseFloat(res) * parseFloat(beforePCharge) / 100) + parseFloat(values.OthersCharge) + parseFloat(values.DealerCharge)
    setactualDownPayment(acDownPayment)

  };





  const handelClickForLowDown = (e) => {
    setbikeOnRoadPrice(parseFloat(values.BikePriceOnRoadPrice));

    setLTVvalue()
    const loanforLow = parseFloat(values.BikePriceSystemPrice) * parseFloat(values.Percentage) / 100;
    setRes(parseFloat(loanforLow));
    setloanforLowPayment(loanforLow);
    setloanforLow(parseFloat(loanforLow));
    // console.log(loanforLow)
    const loanforLowProcessingCharge = parseFloat(loanforLow) * parseFloat(values.ProcessingCharge) / 100;
    // console.log(loanforLowProcessingCharge)
    const originalResult = parseFloat(values.BikePriceOnRoadPrice) - parseFloat(loanforLow) + parseFloat(loanforLowProcessingCharge) + parseFloat(values.DealerCharge) + parseFloat(values.OthersCharge)
    // console.log(originalResult)
    setloanforLowPayment(originalResult);


  }



  const handleSubmitstep2 = (e) => {
    e.preventDefault();
    setLoanTurnue(anothervalues.LoanTenure)
    const a = parseFloat(anothervalues.LoanTenure) / 12;

    const b = parseFloat(anothervalues.InterestRate) / 100;
    const x = (anothervalues.RequiredLoanAmount * a * b) + anothervalues.RequiredLoanAmount
    const z = x / parseFloat(anothervalues.LoanTenure);
    setEmiResult(z);
    const totalPayment = parseFloat(z) * parseFloat(anothervalues.LoanTenure);
    settotalPayment(totalPayment);
    const totalInterest = parseFloat(totalPayment) - anothervalues.RequiredLoanAmount;
    settotalInterest(totalInterest);


  }

  const onChange = (e) => {
    const { name, value } = e.target;
    if (name === "ProcessingCharge") {
      setShowCustomInput(value === "others");
    }
    // setValues({ ...values, [name]: name === "ProcessingCharge" && value !== "others" ? parseFloat(value) : value });
    setValues({ ...values, [e.target.name]: e.target.value });
  };




  const AnotheronChange = (e) => {
    setAnothervalues({ ...anothervalues, [e.target.name]: e.target.value });
  };


  const handleFeedbackClick = () => {
    setShowContactUs(!showContactUs);
  };





  const handleShareClick = () => {
    // Generate the WhatsApp share URL
    const whatsappShareUrl = `https://wa.me/?text=${encodeURIComponent(`
      Bike Price: ₹${Math.round(bikeOnRoadPrice)}
      ${scheme === 'NormalScheme' ? `Down Payment: ₹${Math.round(actualDownPayment)}` : `Low Down Payment: ₹${Math.round(loanforLowPayment)}`}
      Loan Tenure (month): ${LoanTurnue}
      EMI Amount (per month): ₹${Math.round(emiResult)}
    `)}`;

    // Open the WhatsApp share URL
    window.open(whatsappShareUrl, '_blank');
  };







  return (
    <div className="app">
      <form>
        <h1>Bajaj Bike Scheme Calculator</h1>
        <div className="headdingCass">( MADHAB BASAK DMA QS_3269802 BARDDHAMAN TEAM )</div>



        <div className="forRadioButton">
          <label>
            <input
              type="radio"
              value="NormalScheme"
              checked={scheme === 'NormalScheme'}
              onChange={() => setScheme('NormalScheme')}
            />
            Scheme
          </label>
          <label>
            <input
              type="radio"
              value="LowDownPaymentScheme"
              checked={scheme === 'LowDownPaymentScheme'}
              onChange={() => setScheme('LowDownPaymentScheme')}
            />
            Low Down Payment Scheme
          </label>
        </div>


        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}

        {/* Dropdown for Processing Charge */}
        <div className="formInput">
          <label>
            Processing Charge(%)
            <div className="required-asterisk">*</div>
          </label>
          <select className="cssfordropdown"
            name="ProcessingCharge"
            value={values["ProcessingCharge"]}
            onChange={onChange}
            required
          >
            <option value={3.5}>3.5</option>
            <option value={3}>3</option>
            <option value={2.5}>2.5</option>
            <option value="others">Others</option>

          </select>
        </div>
        {showCustomInput && (
          <div className="formInput">
            <label>
              Custom Processing Charge
              <div className="required-asterisk">*</div>
            </label>
            <input
              type="number"
              name="CustomProcessingCharge"
              placeholder="Enter custom charge"
              value={values["CustomProcessingCharge"]}
              onChange={onChange}
              required
            />
          </div>
        )}

        {inputs2.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}



        {scheme === 'NormalScheme' ?
          <button type="button" onClick={handleSubmit}>Calculate LTV (Loan-to-value) ratio</button>
          :
          <button type="button" onClick={handelClickForLowDown}>Calculate Loan Amount</button>}
        {LTVvalue !== undefined && <div className="ltvdesign">LTV is {LTVvalue.toFixed(2)}%</div>}
      </form>


      <div className="formargin"></div>




      {/* another form */}

      {(LTVvalue !== undefined || loanforLow !== undefined) &&
        <form onSubmit={handleSubmitstep2}>
          <h1>EMI Calculator </h1>
          {anotherinputs.map((input) => (
            <FormInput
              key={input.id}
              {...input}
              value={anothervalues[input.name]}
              onChange={AnotheronChange}
            />
          ))}
          <button >Calculate EMI</button>


          {emiResult !== undefined &&
            <>
              <hr className="hrtop" />

              <div className="alignStyle">
                <h1 className="ResStyle">Result</h1>
                  <img onClick={handleShareClick} src="img/whatsapp.jpg" alt="WhatsApp Logo" className="whatsappStyle" />


              </div>


              <div className="forgap">Bike Price (On-Road) - {Math.round(bikeOnRoadPrice)}</div>
              {scheme === 'NormalScheme' && <div className="forgap">Customer's Down Payment - {Math.round(bikeDownPayment)}</div>}
              <div className="forgap">Loan Tenure (Month) - {LoanTurnue}</div>

              <div className="forgap">Total Interest - {Math.round(totalInterest)}</div>
              <div className="forgap">Total Payment - {Math.round(totalPayment)}</div>
              {scheme === 'NormalScheme' ?
                <div className="forgap emitext">Actual Down Payment - {Math.round(actualDownPayment)}</div>
                : <div className="forgap emitext">Low Down Payment - {Math.round(loanforLowPayment)}</div>}
              <div className="emitext">EMI Amount (per month) - {Math.round(emiResult)}</div>

              <hr className="hrbuttom" />
              {/* WhatsApp Share Button */}


            </>}

        </form>
      }



      <div className="tgged">@Website developed by Basudeb Basak</div>
      <div className="feedbackcss" onClick={handleFeedbackClick}>Feedback</div>
      {showContactUs && <Contact showContactUs={showContactUs} setShowContactUs={setShowContactUs} />}


    </div>
  );
};

export default App;